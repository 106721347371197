import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";

export default function Template({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { site, markdownRemark } = data; // data.markdownRemark holds your post data
    const { siteMetadata } = site;
    const { frontmatter, html } = markdownRemark;
    return (
        <Layout>
            <Helmet>
                <title>
                    {frontmatter.title} | {siteMetadata.title}
                </title>
                <meta name="description" content={frontmatter.metaDescription} />
            </Helmet>
            <div className="blog-post-container">
                <article className="post">
                    <div class="relative py-16 bg-white overflow-hidden">
                        <div class="relative px-4 sm:px-6 lg:px-8">
                            <div class="text-lg max-w-prose mx-auto">
                                <h1>
                                    <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">Article</span>
                                    <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{frontmatter.title}</span>
                                    <div className="flex space-x-1 w-full justify-center text-sm text-gray-500">
                                        <time dateTime="2020-03-16">
                                            {frontmatter.date}
                                        </time>
                                    </div>
                                </h1>
                            </div>
                            <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
                                <figure>
                                    {!!frontmatter.thumbnail && (
                                        <img
                                            className="h-48 w-full object-cover"
                                            src={frontmatter.thumbnail}
                                            alt={frontmatter.title}
                                        />
                                    )}
                                </figure>
                                <div
                                    className="blog-post-content"
                                    dangerouslySetInnerHTML={{ __html: html }}
                                />
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </Layout>
    );
}

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        thumbnail
        metaDescription
      }
    }
  }
`;
